
/**
 * disabled Function
 * 判断当前图表类型是否禁用
 * 调用时机：渲染页面
 * @param {Object} selectInfos
 * @param {Object} groupByInfos
 * @param {Message[]|Message} data
 * @returns Boolean
 */
export const iconList = [
  {
    value: 'table',
    label: '表格',
    coverImg: 'table.png',
    disabled: () => false,
    showType: 0
  },
  {
    value: 'line',
    label: '折线图',
    coverImg: 'line-stack.png',
    showType: 1,
    disabled: (selectInfos, groupByInfos) => {
      const groupByInfosLenght = groupByInfos?.length
      if (groupByInfosLenght >= 1 && groupByInfosLenght < 2) {
        return false
      }
      return true
    }
  },
  {
    value: 'bar',
    label: '柱状图',
    coverImg: 'bar-tick-align.png',
    showType: 2,
    disabled: (selectInfos, groupByInfos) => {
      const groupByInfosLenght = groupByInfos?.length
      if (groupByInfosLenght >= 1) {
        return false
      }
      return true
    }
  },
  {
    value: 'pie',
    label: '饼图',
    coverImg: 'pie-simple.png',
    showType: 4,
    disabled: (selectInfos, groupByInfos) => {
      const groupByInfosLenght = groupByInfos?.length
      const selectInfosLength = selectInfos?.length
      if (selectInfosLength <= 4 && groupByInfosLenght > 0 && groupByInfosLenght <= 1) {
        return false
      }
      return true
    }
  },
  {
    value: 'area',
    label: '地图',
    coverImg: 'area.png',
    showType: 5,
    disabled: (selectInfos, groupByInfos) => {
      const groupByInfosLenght = groupByInfos?.length
      const flag = groupByInfos?.some(({colDesc}) => (colDesc.includes('省') || colDesc.includes('市')))
      if (flag && groupByInfosLenght > 0) {
        return false
      }
      return true
    }
  },
  {
    value: 'funnel',
    label: '漏斗图',
    coverImg: 'funnel.png',
    showType: 6,
    disabled: (selectInfos, groupByInfos) => {
      const groupByInfosLenght = groupByInfos?.length
      const selectInfosLength = selectInfos?.length
      if (selectInfosLength <= 5 && groupByInfosLenght > 0 && groupByInfosLenght <= 1) {
        return false
      }
      return true
    }
  },
  // { label: 'scatter', value: 7 },
  {
    value: 'card',
    label: '卡片',
    coverImg: 'card.png',
    showType: 9,
    disabled: (selectInfos, groupByInfos) => {
      const groupByInfosLenght = groupByInfos?.length
      const selectInfosLength = selectInfos?.length
      if (selectInfosLength > 0 && groupByInfosLenght <= 1) {
        return false
      }
      return true
    }
  },
  {
    value: 'radar',
    label: '雷达图',
    coverImg: '',
    showType: 10,
    disabled: (selectInfos, groupByInfos) => {
      const groupByInfosLenght = groupByInfos?.length
      if (groupByInfosLenght === 1 && selectInfos.length === 1) {
        return false
      }
      return true
    }
  },
  // TODO: 新增
  {
    value: 'scatter-simple',
    label: '散点图',
    coverImg: '',
    showType: 11,
    disabled: () => false
  }
]

export function showTypeText() {
  return (showType = 0) => {
    const [row] = iconList.filter(item => item.showType === showType)
    return row
  }
}

