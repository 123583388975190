<template>
  <div class="data-service-center">
    <el-table :data="data" style="width: 100%;">
      <el-table-column
        width="60px"
        align="center"
        prop="prop"
        label="状态"
      >
        <template slot-scope="scoped">
          <el-tag v-if="scoped.row.openCount === 0" size="mini" style="background: #ff9b00; border: none; color: #fff; line-height: 20px;">未读</el-tag>
          <el-tag v-else size="mini" type="info">已读</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="dataServiceTitle" label="服务名称">
        <template slot-scope="scoped">
          <el-button type="text" @click="() => $router.push({name: 'ReportingActivity', query: {id: scoped.row.dataServicePushId}})">{{ scoped.row.dataServiceTitle }}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="intro" label="简介" show-overflow-tooltip />
      <el-table-column
        prop="relationSmartModelCount"
        width="200px"
        align="center"
        label="相关报表数量"
      />
      <el-table-column
        prop="pushAt"
        align="center"
        width="200px"
        label="发布日期"
      />
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 20px;"
      :current-page="pagination.pageNo"
      :total="pagination.total"
      :page-size="pagination.pageSize"
      :page-sizes="pagination.sizes"
      :layout="pagination.layout"
      :hide-on-single-page="false"
      class="pagination"
      background
      @size-change="onSizeChange"
      @current-change="onCurrentChange"
    />
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
export default {
  name: 'DataServiceCenter',
  mixins: [paginationMixin],
  data() {
    return {
      data: []
    }
  },
  computed: {
    isUnread() {
      return this.data.some(item => item.openCount)
    }
  },
  watch: {
    data(newVal) {
      this.$emit('update:isUnread', newVal?.some(item => item.openCount === 0))
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    async getDataList() {
      try {
        const params = this.getParams({
          pushLocation: 1
        })
        this.loading = true
        const {data: {data, totalCount}} = await this.$axios.get(this.$API.queryPushList, {params})
        this.data = data
        this.pagination.total = totalCount
        this.loading = false
      } catch (error) {
        this.$message.error(error.message)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.data-service-center {
  margin-top: 10px;
}
</style>
