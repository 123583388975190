<template>
  <div class="content">
    <el-table
      :data="inviteCodeTable"
      :loading="loading"
      stripe
      style="width: 100%;"
    >
      <el-table-column
        label="活动名称"
        :show-overflow-tooltip="true"
        min-width="100"
        align="center"
        fixed
        prop="sceneTitle"
      />
      <el-table-column
        label="活动类型"
        :show-overflow-tooltip="true"
        min-width="100"
        align="center"
        prop="sceneType"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.sceneType | sceneTypeStr }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :show-overflow-tooltip="true"
        min-width="100"
        label="组织待分配"
        align="center"
        prop="factoryUnassignedCodeCount"
      />
      <el-table-column
        label="任务来源"
        :show-overflow-tooltip="true"
        min-width="100"
        align="center"
        prop="finalOrgName"
      />
      <el-table-column
        label="操作"
        :show-overflow-tooltip="true"
        min-width="200"
        align="center"
        prop="validDay"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="onJump(scope.row)"
          >
            前往分配
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="sorter-box"
      :current-page="searchData.pageNo"
      :page-size="searchData.pageSize"
      :total="total"
      background
      hide-on-single-page
      layout="total, prev, pager, next, jumper"
      @current-change="onCurrentChange"
    />
  </div>
</template>

<script>

export default {
  name: '',
  components: {},
  filters: {
    sceneTypeStr(val) {
      const map = {
        1: '科会',
        4: '调研',
        5: '直播',
        6: '会议'
      }
      return map[val]
    }
  },
  data() {
    return {
      inviteCodeTable: [],
      searchData: {
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      loading: false
    }
  },
  mounted() {
    this.getInviteCodeList()
  },
  methods: {
    onCurrentChange(val) {
      this.searchData.pageNo = val
      this.getInviteCodeList()
    },
    getInviteCodeList() {
      this.loading = true
      this.$axios.get(this.$API.waitAttributeCodes, {params: this.searchData}).then(
        res => {
          this.loading = false
          this.inviteCodeTable = res.data.data
          this.total = res.data.totalCount
        },
        rea => {
          this.loading = false
          this.$message.error(rea.message)
        }
      )
    },
    onJump(row) {
      const map = {
        1: 'meeting',
        4: 'questionnaire',
        5: 'live',
        6: 'webinar'
      }
      this.$router.push({
        path: `/academic/scene/${map[row.sceneType]}/rule/${row.sceneId}`,
        query: {
          disabledBack: true
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.content {
  padding-top: 10px;
}
</style>
