<template>
  <div class="reporting-activity">
    <page-main title back>
      <el-row>
        <el-col>
          <el-form
            ref="form"
            class="reporting-form"
            :model="detail"
            label-width="80px"
          >
            <el-form-item label="活动名称">
              {{ detail.dataServiceTitle }}
            </el-form-item>
            <el-form-item label="简介">
              {{ detail.intro }}
            </el-form-item>
            <el-form-item v-if="detail.content">
              <div class="rich-text" @click="showImage" v-html="detail.content" />
              <!-- <div class="fabulous-btn">
                <el-button type="text"><svg-icon style="font-size: 16px; margin-right: 10px;" :name="detail.active ? 'fabulous' : 'fabulous-act'" />赞一赞</el-button>
              </div> -->
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </page-main>

    <page-main>
      <el-tabs v-model="activeName" class="tabs">
        <el-tab-pane label="相关报表" stripe name="related-reports">
          <el-table :data="detail.smartModelInfoVOS" style="width: 100%;">
            <el-table-column prop="modelName" label="报表名称" />
            <el-table-column prop="status" label="状态">
              <template slot-scope="scoped">
                {{ scoped.row.status === 0 ? '未发布' : scoped.row.status === 2 ? '已发布' : scoped.row.status === 1 ? '预发' : '' }}
              </template>
            </el-table-column>
            <el-table-column prop="intro" label="简介" show-overflow-tooltip />
            <el-table-column
              width="200px"
              align="center"
              prop="prop"
              label="操作"
            >
              <template slot-scope="scoped">
                <el-button :disabled="scoped.row.status === 0" type="text" @click="onCheck(scoped.row)">查看</el-button>
                <el-button :disabled="scoped.row.status === 0" type="text" @click="onEdit(scoped.row)">
                  添加到看板
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div style="margin-top: 10px;">还想要其它报表,可以<el-button type="text" @click="toHelp">点击这里试试</el-button></div>
        </el-tab-pane>
        <!-- <el-tab-pane label="互动评论" name="interactive-comments">456</el-tab-pane> -->
      </el-tabs>
    </page-main>

    <!-- 添加到看板 -->
    <KanbanManagementDialog
      title="添加到看板"
      :no-selection="false"
      :bulletin-board-data.sync="bulletinBoardData"
      :visible.sync="kanbanDialog"
      :chart-data="activeRow"
      :data-service-id="detail.dataServiceId"
      @on-submit="getDetail()"
    />
    <ChartEditDialog
      :title="chartData.modelName"
      :show-source="false"
      :data="chartData"
      type="check"
      :visible.sync="chartEditDialog"
    />
    <el-image-viewer
      v-if="url !== ''"
      :on-close="() =>url = ''"
      :initial-index="getImgSrc(detail.content).indexOf(url)"
      :url-list="getImgSrc(detail.content)"
    />
  </div>
</template>

<script>
import KanbanManagementDialog from './components/KanbanManagementDialog.vue'
import ChartEditDialog from './components/ChartEditDialog.vue'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

export default {
  name: 'ReportingActivity',
  components: { KanbanManagementDialog, ChartEditDialog, ElImageViewer },
  data() {
    return {
      detail: {},
      activeName: 'related-reports',
      bulletinBoardData: [],
      kanbanDialog: false,
      activeRow: {},
      chartEditDialog: false,
      chartData: {},
      selectList: [],
      url: ''
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    async getDetail() {
      try {
        const params = {
          dataServicePushId: this.$route.query.id
        }
        const { data } = await this.$axios.get(this.$API.dataCockpitBoardV1PushDetail, { params })
        this.$route.meta.title = data.dataServiceTitle
        data.smartModelInfoVOS = data.smartModelInfoVOS.map(item => {
          item.dataCockpitBoardIdsEdit = item.dataCockpitBoardIds
          return item
        })
        this.detail = data
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    onCheck(row) {
      this.chartData = row
      this.chartEditDialog = true
    },
    async onEdit(row) {
      this.kanbanDialog = true
      this.selectList = row.dataCockpitBoardIds?.split(',').map(item => ({
        id: item
      }))

      this.activeRow = row
    },
    // 去帮助中心
    toHelp() {
      const routeUrl = this.$router.resolve({ name: 'HelpFeedBack', query: { feedBackType: 2 } })
      window.open(routeUrl.href, '_blank')
    },
    // 大图展示
    showImage(e) {
      if (e.target.tagName === 'IMG') {
        this.url = e.target.src
      }
    },
    getImgSrc(richtext = '') {
      const imgList = []
      richtext.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, (match, capture) => {  // match表示img元素，capture表示img地址
        imgList.push(capture)
      })
      return imgList
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.reporting-activity {
  .reporting-form {
    margin-left: 15px;
    ::v-deep .el-form-item__label {
      text-align: justify;
      text-align-last: justify;
      &::after {
        content: '：';
        position: absolute;
      }
    }
    .fabulous-btn {
      display: flex;
      justify-content: flex-end;
    }
  }
  ::v-deep .rich-text {
    margin-right: 95px;
    // min-height: 203px;
    // background-color: #f5f5f5;
    img {
      max-width: 100% !important;
      height: inherit;
    }
  }
  .tabs {
    ::v-deep .el-tabs {
      .el-tabs__header {
        .el-tabs__nav-wrap {
          overflow: inherit;
          &::after {
            width: calc(100% + 2 * #{$g-main-wrap-padding});
            left: -$g-main-wrap-padding;
            height: 1px;
          }
        }
      }
    }
  }
}
</style>
