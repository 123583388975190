<!--
 * @Author: your name
 * @Date: 2020-11-10 14:57:29
 * @LastEditTime: 2020-11-10 16:33:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/components/MeetingAllotStatModal.vue

-->
<template>
    <el-dialog :title='title' :visible="visible" @close='onClose'>
        <el-table
            v-loading='loading'
            :data='list'
            stripe
            size='small'
            max-height="250"
        >
            <el-table-column
                prop='doctorName'
                align='center'
                label='医生'
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.doctorName || '/' }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop='jobTitle'
                align='center'
                label='职称'
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.jobTitle || '/' }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop='department'
                align='center'
                label='科室'
            >
            </el-table-column>
            <el-table-column
                prop='hospital'
                align='center'
                label='医院'
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.hospital || '/' }}</span>
                </template>
            </el-table-column>
        </el-table>
        <el-row slot="footer" type="flex" justify="center" class="lay-x">
            <el-button type="primary" @click="onClose">关闭</el-button>
        </el-row>
    </el-dialog>
</template>
<script>
import { mapActions } from 'vuex'

export default {
    name: 'ChangeMemberMessageModal',
    data() {
        return {
            list: [],
            loading: false,
            title:'医生详情'
        }
    },
    props: ['visible', 'type', 'itemId'],
    computed: {
        typeLable() {
            return `${this.type}资源数(个)`
        }
    },
    methods: {
        ...mapActions(['getTransformMessage']),
        init() {
            this.loading = true
            this.getTransformMessage(this.itemId).then(res => {
                this.loading = false
                this.list = res.doctors
            }, rea => {
                this.loading = false
            })
        },
        onClose() {
            this.$emit('onClose')
        }
    },
    watch: {
        visible(open) {
            if (open) {
                this.init()
            }
        }
    },
    mounted() {
    }
}
</script>
<style lang="scss" scoped>
.el-button {
  width: 150px;
}
</style>
