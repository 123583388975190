<template>
  <div>
    <el-dialog
      :title="$attrs.title||'看板管理'"
      class="bulletin-board customize-el-dialog"
      v-bind="$attrs"
      width="450px"
      v-on="$listeners"
      @open="getList"
      @close="close"
    >
      <div class="header-form">
        <el-input
          v-model.trim="bulletinBoardForm.title"
          style="width: 180px;"
          placeholder="请输入看板名称"
          clearable
          :maxlength="15"
          @keydown.enter="onBulletinBoardAdd"
        />
        <el-button type="primary" @click="onBulletinBoardAdd">确认添加 <i class="el-icon-plus" /></el-button>
      </div>
      <el-table
        ref="multipleTable"
        v-loading="loading"
        stripe
        height="250px"
        :data="bulletinBoardList"
        class="table"
        style="width: 100%;"
        :row-key="(row) => row.id"
        @selection-change="multipleSelection = $event"
      >
        <el-table-column
          v-if="!noSelection"
          :reserve-selection="true"
          type="selection"
          width="55"
        />
        <el-table-column prop="prop" label="看板名称">
          <template slot-scope="scoped">
            <el-input v-if="scoped.row.edit" v-model="scoped.row.name" placeholder="请输入看板名称" />
            <span v-else>{{ scoped.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="prop"
          label="操作"
          width="104px"
          align="center"
        >
          <template slot-scope="scoped">
            <el-button v-if="scoped.row.edit" type="text" @click="onBulletinBoardEdit('confirm', scoped.row)">完成</el-button>
            <el-button v-else type="text" @click="onBulletinBoardEdit('edit', scoped.row)">编辑</el-button>
            <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              icon="el-icon-info"
              icon-color="red"
              title="确定删除吗？"
              @confirm="onBulletinBoardRemove(scoped.row)"
            >
              <el-button slot="reference" style="margin-left: 5px; color: rgb(232, 49, 47);" type="text"> 删除 </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button class="dialog-btn" @click="$emit('update:visible', false)">取消</el-button>
        <el-button
          v-if="!noSelection"
          class="dialog-btn"
          type="primary"
          @click="noSelection? $emit('update:visible', false) : handleSubmit()"
        >确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {cloneDeep} from 'lodash-es'
export default {
  name: 'KanbanManagementDialog',
  props: {
    // 看板数据
    bulletinBoardData: {
      type: Array,
      default: () => []
    },
    // 是否有checkbox
    noSelection: {
      type: Boolean,
      default: () => true
    },
    // 当前报表基本数据
    chartData: {
      type: Object,
      default: () => ({})
    },
    // 服务id/文章id
    dataServiceId: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      loading: false,
      bulletinBoardForm: {},
      multipleSelection: [],
      selectList: []
    }
  },
  computed: {
    bulletinBoardList: {
      get() {
        return cloneDeep(this.bulletinBoardData)
      },
      set(val) {
        this.$emit('update:bulletinBoardData', val)
      }
    }
  },
  methods: {
    // 获取看板列表数据
    async getList() {
      try {
        this.loading = true
        const params = {
          modelId: this.chartData.modelId
        }
        const { data } = await this.$axios.get(this.$API.queryBoardList, {params})
        this.bulletinBoardList = data

        const selectListSnap = data.filter(item => item.isAdd)
        this.selectList = cloneDeep(selectListSnap)
        this.multipleSelection = cloneDeep(selectListSnap)
        this.toggleSelection()

        this.loading = false
        return data
      } catch (error) {
        this.loading = false
        error.message && this.$message.error(error.message)
      }
    },
    async onBulletinBoardAdd() {
      if (this.bulletinBoardForm.title) {
        try {
          const params = {
            title: this.bulletinBoardForm.title
          }
          await this.$axios({
            method: 'post',
            url: this.$API.dataCockpitBoardV1CreateUpdate,
            params
          })
          this.bulletinBoardForm.title = ''
          this.getList()
        } catch (error) {
          error.message && this.$message.error(error.message)
        }
      } else {
        this.$message.error('请输入看板名称')
      }
    },
    async handleSubmit() {
      let ids = this.selectList.map(item => item.id) || []
      let editIds = this.multipleSelection.map(item => item.id) || []
      if (ids.length < editIds.length) {
        // 添加
        const addIds = editIds.filter(item => {
          if (!ids.includes(item)) {
            return true
          } else if (ids.includes(item)) {
            ids = ids.filter(id => id !== item)
            return false
          }
        })
        if (addIds.length !== 0) {
          await this.handleAdd({smartModelId: this.chartData.modelId, dataCockpitBoardIds: addIds, dataServiceId: this.dataServiceId})
        }
        if (ids.length !== 0) {
          await this.handleCancel({smartModelId: this.chartData.modelId, dataCockpitBoardIds: ids})
        }
      } else if (ids.length > editIds.length) {
        // 移除
        const removeIds = ids.filter(item => {
          if (!editIds.includes(item)) {
            return true
          } else if (editIds.includes(item)) {
            editIds = editIds.filter(id => id !== item)
            return false
          }
        })
        if (removeIds.length !== 0) {
          await this.handleCancel({smartModelId: this.chartData.modelId, dataCockpitBoardIds: removeIds})
        }
        if (editIds.length !== 0) {
          await this.handleAdd({smartModelId: this.chartData.modelId, dataCockpitBoardIds: editIds, dataServiceId: this.dataServiceId})
        }
      } else {
        if (editIds.length !== 0 && ids.length !== 0) {
          await this.handleCancel({smartModelId: this.chartData.modelId, dataCockpitBoardIds: ids})
          await this.handleAdd({smartModelId: this.chartData.modelId, dataCockpitBoardIds: editIds, dataServiceId: this.dataServiceId})
        }
      }
      this.$message.success('编辑成功')
      this.$emit('update:visible', false)
      this.$emit('on-submit')
    },
    // 移除驾驶舱
    async handleCancel(params) {
      try {
        await this.$axios.post(this.$API.dataCockpitBoardV1ModelRemove, params)
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    // 添加到看板
    async handleAdd(params) {
      try {
        await this.$axios.post(this.$API.dataCockpitBoardV1AddSmartModel, params)
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    async onBulletinBoardEdit(type, row) {
      this.bulletinBoardList = this.bulletinBoardList.map(item => {
        if (row.id === item.id) {
          item.edit = !item.edit
        }
        return item
      })
      if (type === 'edit') return
      try {
        const params = {
          title: row.name,
          dataCockpitBoardId: row.id
        }
        await this.$axios({
          method: 'post',
          url: this.$API.dataCockpitBoardV1CreateUpdate,
          params
        })
      } catch (error) {
        error.message && this.$message.error(error.message)
      }
    },
    // 删除面板
    async onBulletinBoardRemove(row) {
      try {
        await this.$axios({
          method: 'post',
          url: this.$API.dataCockpitBoardV1Del,
          params: { dataCockpitBoardId: row.id }
        })
        this.$message.success('删除成功')
        this.getList()
      } catch (error) {
        error.message && this.$message.error(error.message)
      }
    },
    toggleSelection() {
      this.$nextTick(() => {
        const ids = this.selectList.map(item => item.id)
        this.bulletinBoardList.forEach(row => {
          if (ids.includes(row.id)) {
            this.$refs.multipleTable.toggleRowSelection(row, true)
          }
        })
      })
    },
    // 关闭看板弹窗
    close() {
      this.$emit('update:selectList', [])
      this.$refs.multipleTable.clearSelection()
    }
  }
}
</script>

<style lang="scss" scoped>
.header-form {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
