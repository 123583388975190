<template>
  <div v-loading="loading" class="chart-item">
    <div class="title">
      <span>
        <p v-if="item.status !== 2">{{ item.modelName }}</p>
      </span>
      <span v-if="item.status === 2" class="btn-group">
        <el-button type="text" @click="kanbanDialog = true">添加到看板</el-button>
        <el-button v-if="showEdit" type="text" @click="onEdit(item)">编辑</el-button>
        <el-button v-else type="text" @click="onCheck(item)">查看</el-button>
      </span>
    </div>
    <div v-if="item.status === 2" class="chart-wrap">
      <DataChart
        v-if="chartData.smartQueryResultVO"
        class="chart"
        :form-chart-option="chartData.formChartOption"
        :js-code-snippet="chartData.jsCodeSnippet"
        :data="{...chartData.smartQueryResultVO, modelName:item.modelName}"
        :show-type-name="showTypeText(chartData.smartQueryResultVO.desc.showDesc.showType).value"
      />
    </div>
    <div v-else class="lower-shelf">报表下架更新中，请耐心等待！<el-button v-if="showEdit" @click="onDel">移出看板</el-button></div>

    <ChartEditDialog
      :title="actChart.modelName"
      :data="actChart"
      :type="type"
      :show-source="showSource"
      :data-cockpit-board-ids="dataCockpitBoardIds"
      :visible.sync="dialogVisible"
    />
    <!-- 添加到看板 -->
    <KanbanManagementDialog
      title="添加到看板"
      :no-selection="false"
      :chart-data="item"
      :bulletin-board-data.sync="bulletinBoardData"
      :visible.sync="kanbanDialog"
      @on-submit="getDataList? getDataList : () => null"
    />
  </div>
</template>

<script>
import ChartEditDialog from './ChartEditDialog.vue'
import KanbanManagementDialog from './KanbanManagementDialog.vue'
import { showTypeText } from '@/utils/data-chart-show-type'
export default {
  name: 'ChartItem',
  components: {ChartEditDialog, KanbanManagementDialog},
  inject: {
    'getDataList': {
      from: 'getDataList',
      default: () => {}
    }
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    dataCockpitBoardIds: {
      type: Array,
      default: Array
    },
    showEdit: {
      type: Boolean,
      default: true
    },
    showSource: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      kanbanDialog: false,
      chartData: {},
      actChart: {},
      type: '',
      bulletinBoardData: []
    }
  },
  computed: {
    showTypeText
  },
  watch: {
    item: {
      handler(newVal) {
        if (!newVal.modelId) return
        // this.getReportDetail(newVal.modelId)
      },
      immediate: true
    }
  },
  methods: {
    // 获取报表数据
    async getReportDetail(smartModelId = this.item.modelId) {
      try {
        this.loading = true
        const params = { smartModelId }
        const { data } = await this.$axios.get(this.$API.dataCockpitBoardV1SmartModelDetail, { params })
        data.formChartOption = typeof JSON.parse(data.chartOptions || '{}') === 'string' ? {} : JSON.parse(data.chartOptions || '{}')
        this.chartData = data
        return data
      } catch (error) {
        this.$message.error(error.message)
      } finally {
        this.loading = false
      }
    },
    // 移出看板
    async onDel() {
      this.$MessageDialog({
        title: '操作提示',
        message: '<div style="margin: 20px 0;">确认是否移出该报表？</div>'
      }).then(async() => {
        try {
          const params = {
            smartModelId: this.item.modelId,
            dataCockpitBoardIds: this.dataCockpitBoardIds
          }
          await this.$axios.post(this.$API.dataCockpitBoardV1ModelRemove, params)
          this.$message.success('移出成功')
          this.$emit('update:visible', false)
          this.$parent.$parent.getDataList()
        } catch (error) {
          this.$message.error(error)
        }
      })
    },
    // 编辑
    onEdit(row) {
      this.type = ''
      this.actChart = row
      this.dialogVisible = true
    },
    onCheck(row) {
      this.onEdit(row)
      this.type = 'check'
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.chart-item {
  height: 100%;
  background-size: cover;
  position: relative;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid #eee;
  .chart-wrap {
    height: calc(100% - 10px);
    display: flex;
    flex-direction: column;
    padding: 0 5px;
    padding-bottom: 5px;
    .chart {
      flex: 1;
    }
  }
  .title {
    height: 10px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #0f0f0f;
    line-height: 12px;
    position: relative;
    top: 5px;
    z-index: 10;
    .btn-group {
      display: none;
    }
  }
  &:hover {
    .title span {
      display: inline-block;
    }
  }
  .lower-shelf {
    height: 100%;
    width: 100%;
    background-color: rgba($color: #f5f5f5, $alpha: 0.85);
    position: absolute;
    z-index: 1;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
    color: #0f0f0f;
    line-height: 12px;
  }
}
</style>
