<template>
  <div class="data-cockpit">
    <div v-loading="loading" class="data-cockpit-detail">
      <div class="search-box">
        <el-form
          :model="search"
          inline
          class="search-form"
          @submit.native.prevent
        >
          <el-form-item>
            <el-radio-group
              v-model="search.dataCockpitBoardId"
              class="radio-switch"
              size="mini"
              @change="onSearch"
            >
              <el-radio-button label="">推荐看板</el-radio-button>
              <el-radio-button v-for="item in bulletinBoardData" :key="item.id" :label="item.id">
                {{ item.name }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item>
            <el-button
              type="info"
              class="handel-btn"
              plain
              @click="bulletinBoardVisible = true"
            >
              编辑管理看板
            </el-button>
          </el-form-item>

          <el-form-item>
            <el-input
              v-model="search.title"
              v-debounce="[onSearch, 'input']"
              clearable
              class="input"
              prefix-icon="el-icon-search"
              placeholder="搜索报表名称"
              @clear="onSearch"
              @keydown.enter.native="onSearch"
            />
          </el-form-item>
        </el-form>
        <div style="display: flex; align-items: center; padding-bottom: 15px;">
          <el-button
            v-if="search.dataCockpitBoardId"
            class="handel-btn"
            style="border-color: #e1e1e1;"
            @click="configureVisible = true"
          >
            <svg-icon name="button-manage" style="margin-right: 10px; font-size: 14px;" />配置报表
          </el-button>
          <el-button type="primary" @click="$router.push({ name: 'ReportLibrary' })">报表库</el-button>
        </div>
      </div>
      <div class="chart-wrap">
        <div v-for="item in chartList" :key="item.modelId" class="chart-item">
          <ChartItem
            :ref="item.modelId"
            :data-cockpit-board-ids="[search.dataCockpitBoardId].filter(v => v)"
            :show-source="!!search.dataCockpitBoardId"
            :show-edit="!!search.dataCockpitBoardId"
            :item="item"
          />
        </div>
      </div>
      <el-empty v-if="chartList.length === 0" />

      <!-- 分页器 -->
      <el-pagination
        style="margin-top: 20px;"
        :current-page="pagination.pageNo"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.sizes"
        :layout="pagination.layout"
        :hide-on-single-page="false"
        :small="pagination.small"
        class="pagination"
        background
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      />
    </div>
    <!-- dialog -->
    <EditDataKanbanDialog :data-cockpit-board-id="search.dataCockpitBoardId" :visible.sync="configureVisible" />
    <KanbanManagementDialog
      ref="KanbanManagementDialog"
      :bulletin-board-data.sync="bulletinBoardData"
      :visible.sync="bulletinBoardVisible"
    />
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
import EditDataKanbanDialog from './components/EditDataKanbanDialog'
import KanbanManagementDialog from './components/KanbanManagementDialog'
import ChartItem from './components/ChartItem.vue'
import { showTypeText } from '@/utils/data-chart-show-type'
export default {
  name: 'DataCockpit',
  components: { EditDataKanbanDialog, KanbanManagementDialog, ChartItem },
  mixins: [paginationMixin],
  provide() {
    return {
      getDataList: this.getDataList
    }
  },
  data() {
    return {
      dialogVisible: false,
      reportList: [],
      actChart: {},
      loading: false,
      search: {
        dataCockpitBoardId: '',
        title: ''
      },
      configureVisible: false,

      bulletinBoardVisible: false,
      bulletinBoardData: [],

      chartList: [],
      pagination: {
        pageSize: 6,
        sizes: [6, 10, 20]
      }
    }
  },
  computed: {
    showTypeText
  },
  mounted() {
    this.$refs.KanbanManagementDialog.getList().then(() => {
      this.getDataList()
    })
  },
  methods: {
    // 获取报表详情
    async getDataList() {
      try {
        const params = this.getParams(this.search)
        this.loading = true
        const {
          data: { data, totalCount }
        } = await this.$axios.get(this.$API[this.search.dataCockpitBoardId ? 'dataCockpitBoardModelShow' : 'dataCockpitBoardIndexShow'], { params })
        this.chartList = data
        // 优化渲染，减少服务器及页面加载压力
        this.$nextTick(async() => {
          for (let i = 0; i < this.chartList.length; i++) {
            const item = this.chartList[i]
            await this.$refs[item.modelId][0]?.getReportDetail()
          }
        })

        this.pagination.total = totalCount
        this.loading = false
      } catch (error) {
        this.loading = false
        this.$message.error(error.message)
      }
    },
    onSearch() {
      this.pagination.pageNo = 1
      this.getDataList()
    },

    // 获取报表数据
    async getReportDetail(smartModelId) {
      try {
        const params = {
          smartModelId
        }
        const { data } = await this.$axios.get(this.$API.dataCockpitBoardV1SmartModelDetail, { params })
        return data
      } catch (error) {
        this.$message.error(error.message)
      }
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.data-cockpit {
  .search-box {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      display: block;
    }
    .search-form {
      display: flex;
      @media screen and (max-width: 768px) {
        display: block;
      }
      ::v-deep .radio-switch {
        .el-radio-button:first-child .el-radio-button__inner {
          border-radius: 2px 0 0 2px;
        }
        .el-radio-button:last-child .el-radio-button__inner {
          border-radius: 0 2px 2px 0;
        }
      }
      .input {
        ::v-deep .el-input__inner {
          border-radius: 24px;
        }
      }
    }
  }
  .table {
    border: 1px solid #eee;
    border-bottom: 0;
  }
  .dialog-btn {
    width: 90px;
    border-radius: 3px;
  }
  .handel-btn {
    padding: 7px 10px;
  }
  .chart-wrap {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
    gap: 10px;
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }
    .chart-item {
      height: 350px;
    }
  }
}
</style>
