<!--
 * @Author: your name
 * @Date: 2020-12-21 11:26:33
 * @LastEditTime: 2022-03-28 10:38:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory_vue/src/bizComponents/BindMobileModal.vue
-->
<template>
  <el-dialog  :visible="visible" :show-close='false' class="factory-modal" width='540px'>
		<div class="title text-ct">安全提醒</div>
		<div class="content text-ct">
			当前账号还未绑定手机号，为了提升账号安全性，账号敏感操作将会发送短信或公众号提醒，建议立即绑定手机号。
			<div class="hint pointer" @click="onJumpHelp">为何要绑定手机号？</div>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onClose">跳过</el-button>
			<el-button type="primary" @click="onSubmit">去绑定</el-button>
		</span>
  </el-dialog>
</template>
<script>
import {setCookie,getCookie,getUserInfo} from '@/utils/auth'
export default {
  name: 'BindMobileModal',
  data() {
    return {
			loading:false
    }
  },
  props:['visible'],
  computed: {
  },
  methods: {
    init () {
      let login = getUserInfo().login
			let exdays = this.$moment().endOf('month').diff(this.$moment(),'day')+1
			let count = getCookie(`${login}_bindModal`) || 0
      setCookie(++count, `${login}_bindModal`, exdays)
			window.localStorage.setItem(`showBindModal`,'true')
    },
    onClose () {
      this.$emit('onClose')
		},
		onSubmit(){
			this.$router.push({name:'BindMobile'})
		},
		onJumpHelp(){
			let url = this.$router.resolve(`/help/detail/6f5e18a547a0c01aa662dfc2a43854bc`)
      window.open(url.href, '_blank')
		}
  },
  watch: {
    visible (open) {
      if (open) {
        this.init()
      }
    }
  },
  mounted () {

  }
}
</script>
<style lang="scss" scoped>
$color-primary: #3D61E3;
.el-button {
  width: 150px;
}
.factory-modal ::v-deep .el-dialog__header {
  display: none;
}
.factory-modal ::v-deep .el-dialog__body {
  padding: 0;
  max-height: 380px;
  display: flex;
  flex-direction: column;
}
.factory-modal ::v-deep .el-dialog__footer {
  border-top: 1px solid #E9EAEB;
  padding: 30px 70px;
  display: flex;
  justify-content: center;
}
.title {
  color: #272E40;
  font-size: 24px;
  flex-shrink: 0;
  padding: 30px 70px 30px;
}
.content {
  padding: 0 70px;
  color: #272E40;
}
.hint {
  margin: 20px 0;
  color: #adadad;
}
</style>
