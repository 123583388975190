<template>
  <div>
    <page-main class="code-box">
      <el-row>
        <el-col :span="6">
          <div class="item-box">
            <div class="">
              进行中的任务数
            </div>
            <div class="resudue-code">
              {{ inviteCodeTitleData.onGoingCodeCount || inviteCodeTitleData.allCount || 0 }}
            </div>
          </div>
          <div v-if="inviteCodeTitleData.canRecallCount" class="chehui-btn color-desc">
            <div class="recall" type="text" @click="onGorecallCode">撤回</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="item-box item-other">
            <div>组织未分配任务数</div>
            <div class="resudue-code">{{ inviteCodeTitleData.factoryUnassignedCodeCount || 0 }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="item-box item-other">
            <div>代表未分配任务数</div>
            <div class="resudue-code">{{ inviteCodeTitleData.representUnassignedCodeCount || 0 }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="item-box item-other">
            <div>医生待完成任务数</div>
            <div class="resudue-code">{{ inviteCodeTitleData.doctorUnreceivedCodeCount || 0 }}</div>
          </div>
        </el-col>
      </el-row>
    </page-main>
    <page-main class="content-box">
      <template>
        <div class="table-title">
          <span>进行中的任务</span>
          <div>
            <el-button
              v-if="!isMobile"
              :loading="exportLoading"
              type="primary"
              plain
              @click="exportData"
            >
              导出数据
            </el-button>
          </div>
        </div>
        <el-table
          :data="inviteCodeTable"
          :loading="loading"
          stripe
          style="width: 100%;"
        >
          <el-table-column
            label="活动名称"
            :show-overflow-tooltip="true"
            min-width="100"
            align="center"
            prop="project"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.project || '—' }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="活动类型"
            :show-overflow-tooltip="true"
            min-width="100"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.sceneType | sceneTypeStr }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="type"
            align="center"
          >
            <!-- eslint-disable-next-line vue/no-unused-vars -->
            <template slot="header" slot-scope="scope">
              <el-select
                :value="searchData.ownerType"
                placeholder="状态"
                @change="onSelectChange"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </template>
            <template slot-scope="scope">
              <div>
                {{ scope.row.type | typeStr }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            min-width="100"
            label="代表"
            align="center"
            prop="representName"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.representName || '—' }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="医生"
            :show-overflow-tooltip="true"
            min-width="100"
            align="center"
            prop="doctorName"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.doctorName || '—' }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="组织"
            :show-overflow-tooltip="true"
            min-width="100"
            align="center"
            prop="finalOrgName"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.finalOrgName || "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="任务有效期"
            :show-overflow-tooltip="true"
            min-width="100"
            align="center"
            prop="validDay"
          >
            <template slot-scope="scope">
              <span>{{ `${scope.row.representValidDay}天代表执行` }}<br>{{ `${scope.row.validDay}天医生学习` }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="任务剩余时间"
            :show-overflow-tooltip="true"
            min-width="100"
            align="center"
            prop="leftDay"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.leftDay || '0' }}天</span>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <el-pagination
        class="sorter-box"
        size="small"
        :current-page="searchData.pageNo"
        :page-size="searchData.pageSize"
        :total="total"
        :background="!isMobile"
        :pager-count="isMobile ? 5 : 7"
        hide-on-single-page
        :layout="isMobile ? 'prev, pager, next' : 'total, sizes, prev, pager, next, jumper'"
        @current-change="onCurrentChange"
      />
    </page-main>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { isMobile } from '@/utils/util.js'
const typeMap = new Map([
  [1, '医生待完成'],
  ['1', '医生待完成'],
  [2, '代表未分配'],
  ['2', '代表未分配'],
  [3, '组织未分配'],
  ['3', '组织未分配']
])
export default {
  name: 'ToFollowTask',
  filters: {
    typeStr(type) {
      return typeMap.get(type) || '—'
    },
    sceneTypeStr(val) {
      const map = {
        1: '科会',
        4: '调研',
        5: '直播',
        6: '会议',
        12: '播客'
      }
      return map[val] || '—'
    }
  },
  data() {
    return {
      loading: true,
      // ------------------ table header select ----------------
      options: [
        {
          value: '',
          label: '全部'
        },
        {
          value: '1',
          label: '医生待完成'
        },
        {
          value: '2',
          label: '代表未分配'
        },
        {
          value: '3',
          label: '组织未分配'
        }
      ],
      // -------------------- 搜索、页数 -----------------
      timerId: null,
      total: 0,
      searchData: {
        pageNo: 1,
        pageSize: 10,
        ownerType: ''
      },
      exportLoading: false,
      inviteCodeTable: [],
      inviteCodeTitleData: {},
      isMobile: isMobile()
    }
  },
  computed: {
    initSearchData() {
      return JSON.stringify(this.searchData)
    }
  },
  watch: {
    initSearchData: {
      handler(newVal, oldVal) {
        if (JSON.parse(newVal).pageNo === JSON.parse(oldVal).pageNo) {
          this.$set(this.searchData, 'pageNo', 1)
        }
        clearTimeout(this.timerId)
        this.timerId = setTimeout(() => {
          this.onGetInviteCodeTable()
        }, 500)
      },
      deep: true
    }
  },
  mounted() {
    this.onPageInit()
    this.onGetInviteCodeTable()
  },
  methods: {
    ...mapActions(['getInviteCodeTable']),
    onPageInit() {
      this.$axios.get(this.$API.scoreAccountDetail).then(res => {
        this.inviteCodeTitleData = res.data || {}
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    onGetInviteCodeTable() {
      this.loading = true
      this.getInviteCodeTable(this.searchData).then(res => {
        this.inviteCodeTable = res.data.data || []
        this.total = res.data.totalCount || 0
        this.loading = false
      }, () => {
        this.total = 0
        this.loading = false
      })
    },
    onSelectChange(val) {
      if (this.searchData.pageNo === 1 && this.searchData.ownerType === val) {
        this.onGetInviteCodeTable()
      }
      this.searchData = Object.assign({}, this.searchData, { ownerType: val, pageNo: 1 })
    },
    onCurrentChange(options) {
      this.$set(this.searchData, 'pageNo', options)
    },
    onGorecallCode() {
      this.$router.push('/account/recallcode')
    },
    tabChange() {
      this.onPageInit()
    },
    async exportData() {
      try {
        this.exportLoading = true
        const res = await this.$axios.get(this.$API.codeListExport, {params: this.searchData})
        this.$message.success(res.data)
        this.exportLoading = false
      } catch (error) {
        this.exportLoading = false
        this.$message.error(error.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .code-box {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    padding: 50px 0 30px 0;
  }
  .item-box {
    width: 100%;
    height: 80px;
    line-height: 1.7;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgba(90, 90, 90, 1);
  }
  .img-ver {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .item-first {
    border-right: 1px solid #eee;
  }
  .resudue-code {
    font-weight: bold;
    color: #2c3449;
    font-size: 30px;
  }
  .recall {
    color: $color-primary;
    margin-left: 5px;
    cursor: pointer;
  }
  .table-title {
    font-weight: bold;
    padding-bottom: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .content-box {
    height: 100%;
    margin-top: 0;
    padding: 20px 30px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
  .sorter-box {
    padding-top: 20px;
    text-align: center;
    background: #fff;
  }
  .chehui-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
