<template>
  <div class="report-library">
    <page-main back title>
      <div class="search-box">
        <el-form class="search-form" inline>
          <el-form-item>
            <el-radio-group v-model="search.categoryId" size="mini" @change="onSearch">
              <el-radio-button label="">全部</el-radio-button>
              <el-radio-button v-for="item in categoryList" :key="item.categoryId" :label="item.categoryId">{{ item.categoryName }}</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="search.title"
              v-debounce="[onSearch, 'input']"
              clearable
              class="input"
              prefix-icon="el-icon-search"
              placeholder="搜索报表名称"
              @clear="onSearch"
              @keydown.enter.native="onSearch"
            />
          </el-form-item>
        </el-form>
      </div>

      <div v-loading="loading">
        <div class="chart-wrap">
          <div v-for="item in chartList" :key="item.id" class="chart-item">
            <ChartItem
              :ref="item.modelId"
              :show-source="false"
              :show-edit="false"
              :item="item"
            />
          </div>
        </div>
        <el-empty v-if="chartList.length === 0" />

        <!-- 分页器 -->
        <el-pagination
          style="margin-top: 20px;"
          :current-page="pagination.pageNo"
          :total="pagination.total"
          :page-size="pagination.pageSize"
          :page-sizes="pagination.sizes"
          :layout="pagination.layout"
          :hide-on-single-page="false"
          :small="pagination.small"
          class="pagination"
          background
          @size-change="onSizeChange"
          @current-change="onCurrentChange"
        />
      </div>
    </page-main>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
import ChartItem from './components/ChartItem.vue'
export default {
  name: 'ReportLibrary',
  components: { ChartItem },
  mixins: [paginationMixin],
  data() {
    return {
      search: {
        categoryId: '',
        title: ''
      },
      categoryList: [],
      chartList: [],
      loading: false,
      pagination: {
        pageSize: 6,
        sizes: [6, 10, 20]
      }
    }
  },
  mounted() {
    this.getPublishCategoryList()
  },
  methods: {
    async getDataList() {
      try {
        this.loading = true
        const params = this.getParams(this.search)
        const {
          data: { data, totalCount }
        } = await this.$axios.get(this.$API.dataCockpitBoardV1ModelAll, { params })
        this.chartList = data

        // 优化渲染，减少服务器及页面加载压力
        this.$nextTick(async() => {
          for (let i = 0; i < this.chartList.length; i++) {
            const item = this.chartList[i]
            await this.$refs[item.modelId] && this.$refs[item.modelId][0]?.getReportDetail()
          }
        })

        this.pagination.total = totalCount
      } catch (error) {
        console.log('%c [error]', 'font-size:15px; color:#42b983;', error)
      } finally {
        this.loading = false
      }
    },
    // 获取分类
    async getPublishCategoryList() {
      try {
        const { data } = await this.$axios.get(this.$API.publishCategoryList)
        this.categoryList = data
        this.onSearch()
      } catch (error) {
        console.log('%c [error]', 'font-size:15px; color:#42b983;', error)
      }
    },
    onSearch() {
      this.pagination.pageNo = 1
      this.getDataList()
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.report-library {
  @media screen and (min-width: 1920px) {
    .chart-wrap {
      .item {
        height: 400px;
      }
    }
  }
  .chart-wrap {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
    gap: 10px;
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }
    .chart-item {
      height: 350px;
    }
  }
  .search-box {
    display: flex;
    justify-content: space-between;
    .search-form {
      display: flex;
      @media screen and (max-width: 768px) {
        display: block;
      }
      ::v-deep .radio-switch {
        .el-radio-button:first-child .el-radio-button__inner {
          border-radius: 2px 0 0 2px;
        }
        .el-radio-button:last-child .el-radio-button__inner {
          border-radius: 0 2px 2px 0;
        }
      }
      .input {
        ::v-deep .el-input__inner {
          border-radius: 24px;
        }
      }
    }
  }
}
</style>
