<!--
 * @Author: your name
 * @Date: 2020-11-10 14:57:29
 * @LastEditTime: 2020-11-10 16:34:05
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/components/MeetingAllotStatModal.vue
-->
<template>
  <el-dialog :title='title' :visible="visible" @close='onClose'>
    <el-table
      v-loading='loading'
      :data = 'list'
      stripe
      size='small'
      max-height="250"
      >
      <el-table-column
        prop='representName'
        align='center'
        label='代表'
      >
        <template slot-scope="scope">
          <span>{{scope.row.representName || '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop='count'
        align='center'
        :label='typeLable'
      >
      </el-table-column>
      <el-table-column
        prop='orgName'
        align='center'
        label='所在组织'
      >
        <template slot-scope="scope">
          <span>{{scope.row.orgName || '/'}}</span>
        </template>
      </el-table-column>
      </el-table>
      <el-row slot="footer" type="flex" justify="center" class="lay-x">
        <el-button type="primary" @click="onClose" >关闭</el-button>
      </el-row>
  </el-dialog>
</template>
<script>
import {mapActions} from 'vuex'
export default {
  name: 'MeetingAllotMessageModal',
  data() {
    return {
      list: [],
      loading: false
    }
  },
  props:['visible', 'title', 'type','itemId'],
  computed: {
    typeLable () {
      return `${this.type}资源数(个)`
    }
  },
  methods: {
    ...mapActions(['getAllotMessage']),
    init () {
      this.loading = true
      this.getAllotMessage(this.itemId).then(res=> {
        this.loading= false
        this.list = res
      }, rea => {
        this.loading = false
      })
    },
    onClose () {
      this.$emit('onClose')
    }
  },
  watch: {
    visible (open) {
      if (open) {
        this.init()
      }
    }
  },
  mounted () {

  }
}
</script>
<style lang="scss" scoped>
  .el-button {
    width: 150px;
  }
</style>
