<template>
  <el-dialog
    title="编辑数据看板"
    v-bind="$attrs"
    width="480px"
    v-on="$listeners"
    @open="open"
  >
    <p style="margin-bottom: 10px;">选择图表</p>
    <el-table
      v-loading="loading"
      height="280px"
      stripe
      :data="chartList"
      class="table"
      style="width: 100%;"
    >
      <el-table-column prop="modelName" fixed label="图表名称" />
      <el-table-column
        prop="dataCockpitBoardName"
        width="150px"
        show-overflow-tooltip
        label="看板管理"
      >
        <template slot-scope="scoped">
          <el-select
            v-if="scoped.row.edit"
            v-model="scoped.row.dataCockpitBoardEdit"
            value-key="id"
            collapse-tags
            multiple
            class="edit-select"
            placeholder="请选择"
          >
            <el-option
              v-for="item in bulletinBoardList"
              :key="item.id"
              :label="item.name"
              :value="item"
            />
          </el-select>
          <span v-else>{{ scoped.row.dataCockpitBoard.map(item => item.name).join(',') }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="prop"
        label="操作"
        width="80px"
        align="center"
      >
        <template slot-scope="scoped">
          <el-button v-if="!scoped.row.edit" type="text" @click="onConfigureEdit(scoped.row)">编辑</el-button>
          <el-button v-else type="text" @click="complete(scoped.row)">完成</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 20px;"
      :current-page="pagination.pageNo"
      :total="pagination.total"
      :page-size="pagination.pageSize"
      :page-sizes="pagination.sizes"
      :layout="pagination.layout"
      :hide-on-single-page="false"
      :small="pagination.small"
      class="pagination"
      background
      @size-change="onSizeChange"
      @current-change="onCurrentChange"
    />
    <span slot="footer" class="dialog-footer">
      <el-button class="dialog-btn" style="width: 90px;" @click="$emit('update:visible', false)">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
import {cloneDeep} from 'lodash-es'
export default {
  name: 'EditDataKanbanDialog',
  mixins: [paginationMixin],
  props: {
    dataCockpitBoardId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      chartList: [],
      pagination: {
        layout: 'prev, pager, next, jumper'
      },
      bulletinBoardList: []
    }
  },
  methods: {
    open() {
      this.getDataList()
      this.getList()
    },
    // 获取报表详情
    async getDataList() {
      try {
        const params = this.getParams({
          dataCockpitBoardId: this.dataCockpitBoardId
        })
        this.loading = true
        const {data: {data, totalCount}} = await this.$axios.get(this.$API.modelList, {params})
        this.chartList = data.map(item => {
          item.dataCockpitBoardEdit = cloneDeep(item.dataCockpitBoard)
          return item
        })
        this.pagination.total = totalCount
        this.loading = false

      } catch (error) {
        this.loading = false
        this.$message.error(error.message)
      }
    },
    // 编辑
    onConfigureEdit(row) {
      this.chartList = this.chartList.map(item => {
        if (item.modelId === row.modelId) {
          this.$set(item, 'edit', !item.edit)
        } else {
          this.$set(item, 'edit', false)
        }
        return item
      })
    },
    // 完成编辑
    async complete(row) {
      const ids = row.dataCockpitBoard?.map(item => item.id)
      const editIds = row.dataCockpitBoardEdit?.map(item => item.id)
      if (row.dataCockpitBoardEdit.length > row.dataCockpitBoard.length) {
        // 添加
        const addIds = row.dataCockpitBoardEdit.map(item => {
          if (!ids.includes(item.id)) {
            return item.id
          }
        }).filter(item => item)
        await this.handleAdd({smartModelId: row.modelId, dataCockpitBoardIds: addIds})
      } else if (row.dataCockpitBoardEdit.length < row.dataCockpitBoard.length) {
        // 移出
        const removeIds = row.dataCockpitBoard.map(item => {
          if (!editIds.includes(item.id)) {
            return item.id
          }
        }).filter(item => item)
        await this.onConfigureRemove({smartModelId: row.modelId, dataCockpitBoardIds: removeIds})
      } else {
        await this.onConfigureRemove({smartModelId: row.modelId, dataCockpitBoardIds: ids})
        await this.handleAdd({smartModelId: row.modelId, dataCockpitBoardIds: editIds})
      }
      this.onConfigureEdit(row)
      this.getDataList()
    },
    // 移出看板
    async onConfigureRemove({smartModelId, dataCockpitBoardIds}) {
      try {
        const params = {
          smartModelId,
          dataCockpitBoardIds
        }
        await this.$axios.post(this.$API.dataCockpitBoardV1ModelRemove, params)
      } catch (error) {
        this.$message.error(error)
      }
    },
    // 添加到看板
    async handleAdd({smartModelId, dataCockpitBoardIds}) {
      try {
        const params = {
          dataCockpitBoardIds,
          smartModelId
        }
        await this.$axios.post(this.$API.dataCockpitBoardV1AddSmartModel, params)
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    // 获取看板列表数据
    async getList() {
      try {
        this.loading = true
        const { data } = await this.$axios.get(this.$API.queryBoardList)
        this.bulletinBoardList = data
        this.loading = false
        return data
      } catch (error) {
        this.loading = false
        this.$message.error(error.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table {
  .edit-select {
    ::v-deep .el-select__tags {
      >span {
        width: 80%;
        display: flex;
        >span {
          width: 80%;
        }
      }
    }
  }
}
</style>
