<!--
 * @Author: your name
 * @Date: 2020-11-12 17:43:54
 * @LastEditTime: 2020-11-12 18:28:25
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/components/HelpItem.vue
-->
<template>
  <el-row type="flex" justify="space-between" class="help-item" align="middle">
    <div @click="onJump(item.id)">
      <span class="text-info-link">{{ item.title }}</span>
      <el-tag size="mini" type="danger" class="p-margin" v-if="item.latest && !item.hasRead">NEW</el-tag>
    </div>
    <span class="color-assist">{{ item.publishAt }}</span>
  </el-row>
</template>
<script>
export default {
  name: 'HelpItem',
  data() {
    return {}
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    onJump(id) {
      this.onRead(id)
      this.$emit('onJump', id)
    },
    onRead(id = '') {
      this.$emit('onRead', id)
    }
  }
}
</script>
<style lang="scss" scoped>
.help-item {
  line-height: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(233, 234, 235, 1);
}

.p-margin {
  margin-left: 12px;
}

.el-tag {
  border-radius: 2px;
}
</style>
